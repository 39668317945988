import { Box, Paper, Typography, useTheme } from '@mui/material';
import { ProfileData } from 'types/profile.interface';
import { getCountryGrades, getCountryGradingSystem } from 'utils/countryGradingSystems';
import { useProfile } from 'app/marketing/profiles/ProfileContext';

export const AcademicSection = () => {
  const {
    state: { profile, isSharedView },
  } = useProfile() as { state: { profile: ProfileData; isSharedView: boolean } };

  const theme = useTheme();
  const titleColor = theme.palette.primary.main;

  const displayCurriculums = profile.curriculums.length > 0;
  const displayDomesticCurriculums = profile.domesticCurriculums?.length > 0;
  const curriculums = profile.curriculums.map((c) => c.name).join(', ');

  const hasAgeRange = profile.agesAcceptedMin || profile.agesAcceptedMax;
  const ageRange = `${profile.agesAcceptedMin || 'Any'}-${profile.agesAcceptedMax || 'Any'}`;

  const gradingSystem = getCountryGradingSystem(profile.country);
  const grades = getCountryGrades(profile.country);
  const minGrade = grades.find((grade) => grade.equivalentAge === profile.minAcceptedGradeAge);
  const maxGrade = grades.find((grade) => grade.equivalentAge === profile.maxAcceptedGradeAge);
  const hasGrades = minGrade && maxGrade;
  const gradesAccepted = `${minGrade?.name} to ${maxGrade?.name}`;

  const hasAcceptsGraduatedStudents = typeof profile.acceptsGraduatedStudents === 'boolean';
  const hasIsPlus1School = typeof profile.isPlus1School === 'boolean';

  return (
    <section>
      <Paper sx={{ marginBottom: '10px' }}>
        <Box style={{ padding: '20px' }}>
          <Typography variant="h5" mb={2} fontSize="24px" fontWeight={700} color={titleColor}>
            Academic
          </Typography>
          <Box display="flex" flexDirection="row" justifyContent="space-between">
            <Box>
              <Typography p={0.5} fontWeight="600">
                Admission requirements
              </Typography>
              {hasAgeRange && (
                <Typography p={0.5}>
                  Ages accepted: <strong>{ageRange}</strong>
                </Typography>
              )}
              {hasGrades && (
                <Typography p={0.5}>
                  Accepting {gradingSystem.prefix.toLowerCase()}: <strong>{gradesAccepted}</strong>
                </Typography>
              )}
              {profile.aeasScoreMin && (
                <Typography p={0.5}>
                  AEAS: <strong>{profile.aeasScoreMin}</strong>
                </Typography>
              )}
              {profile.cambridgeScoreMin && (
                <Typography p={0.5}>
                  Cambridge: <strong>{profile.cambridgeScoreMin}</strong>
                </Typography>
              )}
              {profile.eltisScoreMin && (
                <Typography p={0.5}>
                  ELTIS 2: <strong>{profile.eltisScoreMin}</strong>
                </Typography>
              )}
              {profile.idatScoreMin && (
                <Typography p={0.5}>
                  IDAT: <strong>{profile.idatScoreMin}</strong>
                </Typography>
              )}
              {profile.ieltsScoreMin && (
                <Typography p={0.5}>
                  IELTS: <strong>{profile.ieltsScoreMin}</strong>
                </Typography>
              )}
              {profile.pearsonScoreMin && (
                <Typography p={0.5}>
                  Pearson: <strong>{profile.pearsonScoreMin}</strong>
                </Typography>
              )}
              {profile.toeflScoreMin && (
                <Typography p={0.5}>
                  TOEFL: <strong>{profile.toeflScoreMin}</strong>
                </Typography>
              )}
              {profile.interview && (
                <Typography p={0.5}>
                  Interview: <strong>{profile.interview}</strong>
                </Typography>
              )}
              {hasAcceptsGraduatedStudents && (
                <Typography p={0.5}>
                  Accepts graduate students: <strong>{profile.acceptsGraduatedStudents ? 'Yes' : 'No'}</strong>
                </Typography>
              )}
              {profile.hostFamilyRequiredBefore && (
                <Typography p={0.5}>
                  Host family requirements: <strong>{profile.hostFamilyRequiredBefore}</strong>
                </Typography>
              )}
            </Box>
            <Box>
              <Typography p={0.5} fontWeight="600">
                Academic stats
              </Typography>
              <Typography p={0.5}>
                Diploma year offered: <strong>{profile.usOneYearDiploma ? 'Yes' : 'No'}</strong>
              </Typography>
              {profile.averageSatScore && (
                <Typography p={0.5}>
                  Average SAT score: <strong>{profile.averageSatScore}</strong>
                </Typography>
              )}
              <Typography p={0.5}>
                English as Second Language (ESL) support: <strong>{profile.englishLangSupport ? 'Yes' : 'No'}</strong>
              </Typography>
              {displayCurriculums && (
                <Typography p={0.5}>
                  Curriculum offered: <strong>{curriculums}</strong>
                </Typography>
              )}
              {displayDomesticCurriculums && (
                <Typography p={0.5}>
                  Domestic Curriculum offered: <strong>{profile.domesticCurriculums.join(', ')}</strong>
                </Typography>
              )}
              {hasIsPlus1School && (
                <Typography p={0.5}>
                  1+1 School: <strong>{profile.isPlus1School ? 'Yes' : 'No'}</strong>
                </Typography>
              )}
              {profile.sportsParticipationCompetitiveness && (
                <Typography p={0.5}>
                  Sports Participation Competitiveness: <strong>{profile.sportsParticipationCompetitiveness}</strong>
                </Typography>
              )}
            </Box>
          </Box>
        </Box>
      </Paper>
      {!isSharedView && profile.specialNotes && (
        <Paper sx={{ marginBottom: '10px' }}>
          <Box style={{ padding: '20px' }}>
            <Typography variant="h6" color="secondary" mb={1}>
              Special Notes
            </Typography>
            <Typography color="secondary">{profile.specialNotes}</Typography>
          </Box>
        </Paper>
      )}
    </section>
  );
};
