import { CoedStatuses } from 'types/coed-statuses.enum';
import { ProfileData } from 'types/profile.interface';

export type EditableProfileFields = Pick<
  ProfileData,
  | 'isPublished'
  | 'coedStatus'
  | 'dayOrBoarding'
  | 'isPrivateSchool'
  | 'totalStudentCount'
  | 'description'
  | 'showHighlightsSection'
  | 'highlightIcon1'
  | 'highlightText1'
  | 'highlightIcon2'
  | 'highlightText2'
  | 'highlightIcon3'
  | 'highlightText3'
  | 'testimonials'
  | 'curriculums'
  | 'currency'
  | 'studyPrograms'
  | 'homestayAccomodation'
  | 'averageClassSize'
  | 'internationalStudentPercentage'
  | 'showAcademicInfoSection'
  | 'aeasScoreMin'
  | 'cambridgeScoreMin'
  | 'eltisScoreMin'
  | 'idatScoreMin'
  | 'ieltsScoreMin'
  | 'pearsonScoreMin'
  | 'toeflScoreMin'
  | 'averageSatScore'
  | 'englishLangSupport'
  | 'graduateStudentAccepted'
  | 'usOneYearDiploma'
  | 'agesAcceptedMin'
  | 'agesAcceptedMax'
  | 'profileFeatures'
  | 'showFeaturesSection'
  | 'minAcceptedGradeAge'
  | 'maxAcceptedGradeAge'
  | 'religiousAffiliation'
  | 'showTestimonialsSection'
  | 'nearCityAddressLine1'
  | 'nearCityGooglePlaceId'
  | 'nearCityLatLng'
  | 'airportAddressLine1'
  | 'airportGooglePlaceId'
  | 'airportLatLng'
  | 'domesticCurriculums'
> & {
  // Pseudo-fields required by react-form-hook for multi-field controllers (or whatever)
  agesAccepted: number[];
  nearCity: string | null;
  airport: string | null;
};

export const getEditableProfileFields = (profile: ProfileData): EditableProfileFields => ({
  isPublished: profile.isPublished || false,
  coedStatus: profile.coedStatus || CoedStatuses.CoEd,
  dayOrBoarding: profile.dayOrBoarding || 'day',
  isPrivateSchool: profile.isPrivateSchool || false,
  totalStudentCount: profile.totalStudentCount || 0,
  description: profile.description?.length > 0 ? profile.description : [{ type: 'paragraph', children: [{ text: '' }] }],
  showHighlightsSection: profile.showHighlightsSection || false,
  highlightIcon1: profile.highlightIcon1 || 'highlight',
  highlightText1: profile.highlightText1 || '',
  highlightIcon2: profile.highlightIcon2 || 'highlight',
  highlightText2: profile.highlightText2 || '',
  highlightIcon3: profile.highlightIcon3 || 'highlight',
  highlightText3: profile.highlightText3 || '',
  testimonials: profile.testimonials || [],
  curriculums: profile.curriculums || [],
  currency: profile.currency || 'USD',
  studyPrograms: profile.studyPrograms || [],
  homestayAccomodation: profile.homestayAccomodation || null,
  averageClassSize: profile.averageClassSize || 20,
  internationalStudentPercentage: profile.internationalStudentPercentage || 3,
  showAcademicInfoSection: profile.showAcademicInfoSection || false,
  aeasScoreMin: profile.aeasScoreMin || null,
  cambridgeScoreMin: profile.cambridgeScoreMin || null,
  eltisScoreMin: profile.eltisScoreMin || null,
  idatScoreMin: profile.idatScoreMin || null,
  ieltsScoreMin: profile.ieltsScoreMin || null,
  pearsonScoreMin: profile.pearsonScoreMin || null,
  toeflScoreMin: profile.toeflScoreMin || null,
  averageSatScore: profile.averageSatScore || null,
  englishLangSupport: profile.englishLangSupport || false,
  graduateStudentAccepted: profile.graduateStudentAccepted || false,
  usOneYearDiploma: profile.usOneYearDiploma || false,
  agesAccepted: [profile.agesAcceptedMin || 13, profile.agesAcceptedMax || 18],
  agesAcceptedMin: profile.agesAcceptedMin || 13,
  agesAcceptedMax: profile.agesAcceptedMax || 18,
  profileFeatures: profile.profileFeatures || [],
  showFeaturesSection: profile.showFeaturesSection || false,
  minAcceptedGradeAge: profile.minAcceptedGradeAge || null,
  maxAcceptedGradeAge: profile.maxAcceptedGradeAge || null,
  religiousAffiliation: profile.religiousAffiliation || null,
  showTestimonialsSection: profile.showTestimonialsSection || false,
  nearCity: profile.nearCityAddressLine1 || null,
  nearCityAddressLine1: profile.nearCityAddressLine1 || null,
  nearCityGooglePlaceId: profile.nearCityGooglePlaceId || null,
  nearCityLatLng: profile.nearCityLatLng || null,
  airport: profile.airportAddressLine1 || null,
  airportAddressLine1: profile.airportAddressLine1 || null,
  airportGooglePlaceId: profile.airportGooglePlaceId || null,
  airportLatLng: profile.airportLatLng || null,
  domesticCurriculums: profile.domesticCurriculums || [],
});

// TODO: proper typing solution
export const getProfileFields = (editableFields: EditableProfileFields & any): Partial<ProfileData> => {
  const profile = {
    ...editableFields,
    averageSatScore: parseInt(editableFields.averageSatScore, 10),
    aeasScoreMin: parseInt(editableFields.aeasScoreMin, 10),
    cambridgeScoreMin: parseInt(editableFields.cambridgeScoreMin, 10),
    eltisScoreMin: parseInt(editableFields.eltisScoreMin, 10),
    idatScoreMin: parseInt(editableFields.idatScoreMin, 10),
    ieltsScoreMin: parseFloat(editableFields.ieltsScoreMin),
    pearsonScoreMin: parseInt(editableFields.pearsonScoreMin, 10),
    toeflScoreMin: parseInt(editableFields.toeflScoreMin, 10),
    totalStudentCount: parseInt(editableFields.totalStudentCount, 10),
    averageClassSize: parseInt(editableFields.averageClassSize, 10),
    internationalStudentPercentage: parseInt(editableFields.internationalStudentPercentage, 10),
  };

  if (editableFields.agesAccepted) {
    profile.agesAcceptedMin = editableFields.agesAccepted![0];
    profile.agesAcceptedMax = editableFields.agesAccepted![1];
    delete profile.agesAccepted;
  }

  if (editableFields.nearCity) {
    profile.nearCityAddressLine1 = editableFields.nearCity.addressLine1;
    profile.nearCityGooglePlaceId = editableFields.nearCity.googlePlaceId;
    profile.nearCityLatLng = editableFields.nearCity.latLng;
  }

  if (editableFields.airport) {
    profile.airportAddressLine1 = editableFields.airport.addressLine1;
    profile.airportGooglePlaceId = editableFields.airport.googlePlaceId;
    profile.airportLatLng = editableFields.airport.latLng;
  }

  // Always delete these two as they are not required and are used to handle multiple points of data in one controller
  delete profile.nearCity;
  delete profile.airport;

  return profile;
};
